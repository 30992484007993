import {
  GRANT_CHECK_TYPE,
  GRANT_STATES,
  useCallSuccess,
  useGrants,
  useJobTelemetryApiCall,
} from '@jarvis/react-portal-addons';
import { useCallback, useMemo, useState } from 'react';

const getISODate = date => {
  const dateString = date.toISOString();
  return dateString.substring(dateString, dateString.indexOf('T'));
};
const grantStates = [GRANT_STATES.ENABLED];

const useForestFirstGrantsCall = ({ authProvider, stack, grantsInterface }) => {
  const [pageCount, setPageCount] = useState(0);
  const params = useMemo(() => {
    const date = new Date();
    const endDate = getISODate(date);
    date.setDate(1);
    const startDate = getISODate(date);
    return { startDate, endDate };
  }, []);

  const jobTelemetry = useJobTelemetryApiCall({
    authProvider,
    stack,
    params,
    init: {
      printPages: true,
      printArea: false,
      scan: false,
      fax: false,
    },
  });
  const { grantsCall } = useGrants({
    init: false,
    grantsInterface,
    states: grantStates,
    checkType: GRANT_CHECK_TYPE.DEVICES,
  });
  const onJobTelemetrySuccess = useCallback(
    (_, payload) => {
      const { printCounters } = payload.print.data.data;
      const grants = printCounters.map(({ deviceId }) => ({
        grant: 'ws-hp.com/positiveprinting',
        level: 'DEVICE',
        states: grantStates,
        deviceId,
      }));
      grantsCall.makeApiCall({ grants });
    },
    [grantsCall],
  );

  useCallSuccess({ call: jobTelemetry, onSuccess: onJobTelemetrySuccess });

  const onGrantsSuccess = useCallback(
    (_, payload) => {
      const validDeviceIds = Object.entries(payload)
        .filter(([, grants]) => grants['ws-hp.com/positiveprinting'] === true)
        .map(([deviceId]) => deviceId);

      const { printCounters } = jobTelemetry.data.print.data.data;
      const forestFirstCounters = printCounters.filter(({ deviceId }) => validDeviceIds.includes(deviceId));
      let totalMediaSheetsCompleted = 0;
      forestFirstCounters.forEach(({ counters }) => {
        counters.forEach(counter => {
          totalMediaSheetsCompleted += counter.mediaSheetsCompleted || 0;
        });
      });

      setPageCount(totalMediaSheetsCompleted);
    },
    [jobTelemetry.data.print.data?.data],
  );

  useCallSuccess({ call: grantsCall, onSuccess: onGrantsSuccess });

  const jobTelemetryMakeApiCall = jobTelemetry.makeApiCall;
  const makeApiCall = useCallback(() => {
    jobTelemetryMakeApiCall();
  }, [jobTelemetryMakeApiCall]);

  return {
    error: jobTelemetry.error || grantsCall.error,
    loading: jobTelemetry.pending,
    data: { lastUpdated: Date.now(), pageCount },
    makeApiCall,
  };
};

export default useForestFirstGrantsCall;
