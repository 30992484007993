import { useCallback, useEffect, useState } from 'react';
import { path } from 'ramda';
import { AccountMgtSvcClient, TelemetryClient } from '@jarvis/web-stratus-client';

const USE_MOCK = false;
const sleep = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

const useStateManager = ({
  accountId,
  authProvider,
  stack,
  store,
  init,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    lastUpdated: null,
    pageCount: null,
  });

  const fetch = useCallback(async () => {
    if (process.env.NODE_ENV === 'development') {
      if (USE_MOCK) {
        await sleep(5000);
        return {
          accountId: 'account',
          impressionsCompleted: 123,
          impressionsCompletedColor: 321,
          mediaSheetsCompleted: 444,
        };
      }
    }

    let impressionsCompleted = 0;
    let impressionsCompletedColor = 0;
    let mediaSheetsCompleted = 0;

    const forestFirstEnabledStates = ['ENABLED'];
    const isForestFirstEnabled = infoResponse => {
      const list = path(['data', 'programInfo', 'entitlementList'], infoResponse);
      const service = list?.find(item => item.serviceId === 'ws-hp.com/positiveprinting') ?? undefined;
      return service && forestFirstEnabledStates.includes(service.state);
    };

    const getISODate = date => {
      const dateString = date.toISOString();
      return dateString.substring(dateString, dateString.indexOf('T'));
    };
    const date = new Date();
    const endDate = getISODate(date);
    date.setDate(1);
    const startDate = getISODate(date);

    const jobTelemetryClient = new TelemetryClient(stack, authProvider);
    const countersResponse = await jobTelemetryClient.getPrintCountersByTenancy(
      undefined, undefined, startDate, endDate,
    );

    if (countersResponse.status >= 200 && countersResponse.status < 300) {
      const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
      const deviceProgramInfoCalls = countersResponse.data.printCounters.map(async counter => (
        accountMgtSvcClient.getDeviceProgramInfos(accountId, counter.deviceId).catch(e => e)
      ));

      const [
        deviceProgramInfoCallsResolved,
      ] = await Promise.all([Promise.all(deviceProgramInfoCalls)]);

      deviceProgramInfoCallsResolved.forEach((infoResponse, idx) => {
        if (!isForestFirstEnabled(infoResponse)) {
          return;
        }
        countersResponse.data.printCounters[idx].counters.forEach(counter => {
          if (counter.impressionsCompleted) {
            impressionsCompleted += counter.impressionsCompleted;
          }
          if (counter.impressionsCompletedColor) {
            impressionsCompletedColor += counter.impressionsCompletedColor;
          }
          if (counter.mediaSheetsCompleted) {
            mediaSheetsCompleted += counter.mediaSheetsCompleted;
          }
        });
      });
    }

    return {
      accountId,
      impressionsCompleted,
      impressionsCompletedColor,
      mediaSheetsCompleted,
    };
  }, [accountId, authProvider, stack]);

  const resolvePageCount = useCallback(async ({ forceUpdate } = {}) => {
    let { state: { jobTelemetry } = {} } = store || {};
    if (jobTelemetry && !forceUpdate) {
      setData(jobTelemetry);
    } else if (authProvider && stack
      && (
        forceUpdate || (!loading && !error && !data.lastUpdated)
      )
    ) {
      try {
        setLoading(true);
        setError(false);

        const { mediaSheetsCompleted } = await fetch();
        jobTelemetry = {
          lastUpdated: Date.now(),
          pageCount: mediaSheetsCompleted,
        };

        setData(jobTelemetry);

        if (store) {
          store.setState({ jobTelemetry });
        }
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [authProvider, data.lastUpdated, error, fetch, loading, stack, store]);

  useEffect(() => {
    if (init) {
      resolvePageCount();
    }
  }, [init, resolvePageCount]);

  return {
    data,
    error,
    loading,
    makeApiCall: () => resolvePageCount({ forceUpdate: true }),
  };
};

export default useStateManager;
