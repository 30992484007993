import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@veneer/core';
import {
  ErrorWidget,
  LoaderWidget,
  useI18n,
} from '@jarvis/react-portal-addons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Body,
  Counter,
  Description,
  HeaderIcon,
  Image,
  Left,
  PagesText,
  Right,
  SubTitle,
} from './styles';
import Images from '../../assets/images';
import useForestFirstGrantsCall from '../../utils/hooks/useForestFirstGrantsCall';
import useStateManager from '../../utils/hooks/useStateManager';
import {
  CardContent,
  CardHeader,
  CardHeaderTitle,
  CardSeparator,
  WidgetContainer,
} from '../styles';

const ImpactWidget = props => {
  const { t } = useI18n();
  const {
    shell: {
      v1: {
        authProvider,
        orgSelector,
      } = {
        authProvider: null,
        orgSelector: null,
        store: null,
      },
    },
    stack,
  } = props;

  const { grantsSupport } = useFlags();
  const grantsInterface = props?.shell?.v1?.grants;

  const forestFirstApiCall = useStateManager({
    accountId: orgSelector.getOrgTenantId(),
    authProvider,
    stack,
    init: !grantsSupport,
  });

  const forestFirstWithGrantsCall = useForestFirstGrantsCall({
    authProvider,
    init: grantsSupport,
    stack,
    grantsInterface,
  });

  const {
    loading,
    error,
    data: { pageCount } = {
      pageCount: null,
    },
  } = grantsSupport ? forestFirstWithGrantsCall : forestFirstApiCall;

  const onRetry = useCallback(() => {
    if (grantsSupport) {
      forestFirstWithGrantsCall.makeApiCall();
    } else {
      forestFirstApiCall.makeApiCall();
    }
  }, [forestFirstApiCall, forestFirstWithGrantsCall, grantsSupport]);

  return (
    <WidgetContainer>
      <Card
        data-testid="react-smb-forest-first-sustainable-impact-card"
        appearance="dropShadow"
        content={(
          <CardContent>
            <CardHeader>
              <HeaderIcon />
              <CardHeaderTitle
                className="subtitle-regular"
                data-testid="react-smb-forest-first-sustainable-impact-title"
              >
                {t('impactCard.header')}
              </CardHeaderTitle>
            </CardHeader>

            <CardSeparator />

            <Body>
              <Left>
                {loading && !error && (
                  <LoaderWidget fullScreen data-testid="react-smb-forest-first-printed-pages-counter-loader" />
                )}
                {!loading && error && (
                  <ErrorWidget
                    data-testid="react-smb-forest-first-printed-pages-counter-error"
                    fullScreen
                    onRetry={onRetry}
                    labelBack={t('errorWidget.back')}
                    labelRetry={t('errorWidget.retry')}
                    message={t('errorWidget.defaultMessage')}
                  />
                )}
                {!loading && !error && (
                  <>
                    <Counter
                      className="title-regular"
                      data-testid="react-smb-forest-first-printed-pages-counter"
                    >
                      {pageCount}
                    </Counter>
                    <PagesText
                      data-testid="react-smb-forest-first-printed-pages-description"
                      className="body-large"
                      dangerouslySetInnerHTML={{
                        __html: t('impactCard.bodyCopy1',
                          { linebreak: '<br>', interpolation: { escapeValue: false } }),
                      }}
                    />
                  </>
                )}
              </Left>
              <Image src={Images.forest} alt="forest" />
              <Right>
                <SubTitle className="subtitle-regular" data-testid="react-smb-forest-first-forest-restor-title">
                  {t('impactCard.subhead')}
                </SubTitle>
                <Description data-testid="react-smb-forest-first-forest-restor-description" className="body">
                  {t('impactCard.bodyCopy2')}
                </Description>
              </Right>
            </Body>
          </CardContent>
        )}
      />
    </WidgetContainer>
  );
};

ImpactWidget.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
  stack: PropTypes.number.isRequired,
};

export default ImpactWidget;
